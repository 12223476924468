import type {File, Image} from '@bttr-devs/nuxt-layer-sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: number | string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Email: string;
  Iban: string;
  Image: Image;
  JSON: any;
  Mixed: any;
  Null: null;
  PrivateFile: any;
  PublicFile: any;
  Upload: any;
  Uuid: string;
};

export type Aanmelding = {
  __typename?: 'Aanmelding';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  time?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  training?: Maybe<Training>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type AanmeldingPagination = {
  __typename?: 'AanmeldingPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Aanmelding>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  geodata?: Maybe<Scalars['JSON']>;
  house_number?: Maybe<Scalars['String']>;
  house_number_suffix?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type BeforeAndAfter = {
  __typename?: 'BeforeAndAfter';
  additional_products?: Maybe<Scalars['String']>;
  additional_treatments?: Maybe<Scalars['String']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  after: Array<Scalars['Image']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  before: Array<Scalars['Image']>;
  capabilities: Array<Capability>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  product: Product;
  time_transpired?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total_treatments?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type BeforeAndAfterAfterArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type BeforeAndAfterBeforeArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Bestelling = {
  __typename?: 'Bestelling';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Organisatie>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  entercoins?: Maybe<Scalars['Int']>;
  entercoins_change?: Maybe<Scalars['Int']>;
  house_number?: Maybe<Scalars['String']>;
  house_number_suffix?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  order_date?: Maybe<Scalars['DateTime']>;
  order_lines: Array<BestellingLijn>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  secured_shipment?: Maybe<Scalars['Boolean']>;
  status: OrderStatusEnum;
  street?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  total?: Maybe<Scalars['Float']>;
  total_marketing_material?: Maybe<Scalars['Int']>;
  total_products?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  uuid: Scalars['Uuid'];
  zipcode?: Maybe<Scalars['String']>;
};

export type BestellingLijn = {
  __typename?: 'BestellingLijn';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  entercoins?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_gift?: Maybe<Scalars['Boolean']>;
  order: Bestelling;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  quantity?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type BestellingPagination = {
  __typename?: 'BestellingPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Bestelling>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Brand = {
  __typename?: 'Brand';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  background_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  logo?: Maybe<Scalars['Image']>;
  marketing_budget_percentage?: Maybe<Scalars['Float']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};


export type BrandBackground_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type BrandLogoArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  cart_items: Array<CartItem>;
  count: Scalars['Int'];
  delivery_cost: Scalars['Float'];
  entercoins_cost: Scalars['Int'];
  entercoins_receive: Scalars['Int'];
  marketing_budget: Scalars['Float'];
  marketing_product_count: Scalars['Int'];
  marketing_sub_total: Scalars['Float'];
  minimum_order_amount: Scalars['Float'];
  product_count: Scalars['Int'];
  product_sub_total: Scalars['Float'];
  sub_total: Scalars['Float'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
  volume_discount: Scalars['Float'];
};

export enum CartActionEnum {
  Add = 'add',
  Delete = 'delete',
  Update = 'update'
}

export type CartItem = {
  __typename?: 'CartItem';
  is_gift: Scalars['Boolean'];
  product: Product;
  quantity: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  ancestors: Array<Category>;
  capabilities: Array<Capability>;
  children: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  depth?: Maybe<Scalars['Int']>;
  descendants: Array<Category>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  name: Scalars['String'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type CategoryActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type CategoryImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Download = {
  __typename?: 'Download';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloads: Array<Scalars['PrivateFile']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type DownloadActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type DownloadImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type DownloadPagination = {
  __typename?: 'DownloadPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data: Array<Download>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int'];
  schema: Scalars['JSON'];
  schema_v2: Scalars['Mixed'];
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['Uuid'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
};

export type FormField = {
  __typename?: 'FormField';
  fields: Array<FormField>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  regex?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  unique?: Maybe<Scalars['Boolean']>;
};

export type FormFieldAttachment = {
  attachment: Scalars['Upload'];
  field_name: Scalars['String'];
};

export enum LocaleEnum {
  Nl = 'nl'
}

export type Menu = {
  __typename?: 'Menu';
  items?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  children?: Maybe<Array<Maybe<MenuItem>>>;
  scope?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']>;
  beforeAndAfter?: Maybe<Scalars['Null']>;
  changePassword?: Maybe<Scalars['Null']>;
  cloneOrder?: Maybe<Scalars['Null']>;
  disableTwoFactor?: Maybe<Scalars['Boolean']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']>;
  logout?: Maybe<Scalars['Boolean']>;
  placeOrder?: Maybe<Scalars['Null']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  submitForm?: Maybe<Scalars['Null']>;
  trainingRegistration?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateCart?: Maybe<Scalars['Null']>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email'];
  invite: Scalars['String'];
  password: Scalars['String'];
};


export type MutationBeforeAndAfterArgs = {
  additional_products?: InputMaybe<Scalars['String']>;
  additional_treatments?: InputMaybe<Scalars['String']>;
  after: Scalars['Upload'];
  before: Scalars['Upload'];
  comments?: InputMaybe<Scalars['String']>;
  product: Scalars['String'];
  time_transpired?: InputMaybe<Scalars['String']>;
  total_treatments?: InputMaybe<Scalars['String']>;
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String'];
  new_password: Scalars['String'];
  new_password_confirm: Scalars['String'];
};


export type MutationCloneOrderArgs = {
  uuid: Scalars['Uuid'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
  two_factor_code?: InputMaybe<Scalars['String']>;
};


export type MutationPlaceOrderArgs = {
  address: Scalars['ID'];
  secured_shipment?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSubmitFormArgs = {
  attachments?: InputMaybe<Array<FormFieldAttachment>>;
  form_data: Scalars['String'];
  form_id: Scalars['ID'];
  g_recaptcha_response?: InputMaybe<Scalars['String']>;
};


export type MutationTrainingRegistrationArgs = {
  training: Scalars['ID'];
  trainingDate: Scalars['ID'];
};


export type MutationUpdateCartArgs = {
  action: CartActionEnum;
  is_gift?: InputMaybe<Scalars['Boolean']>;
  product: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type Offer = {
  __typename?: 'Offer';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  product?: Maybe<Product>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type OfferActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type OfferImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export enum OrderStatusEnum {
  Canceled = 'canceled',
  Completed = 'completed',
  Pending = 'pending'
}

export type Organisatie = {
  __typename?: 'Organisatie';
  accountmanager?: Maybe<User>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  addresses: Array<Address>;
  addresses_count?: Maybe<Scalars['Int']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['Email'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  invoice_address?: Maybe<Address>;
  invoice_email?: Maybe<Scalars['Email']>;
  kvk_number?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  mobile_number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  vat_number?: Maybe<Scalars['String']>;
};


export type OrganisatieActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type OrganisatieImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Page = {
  __typename?: 'Page';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  children?: Maybe<Array<Maybe<Page>>>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  template?: Maybe<Template>;
  template_name: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};


export type PageActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type PageImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  active_category?: Maybe<Category>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  artikelnummer?: Maybe<Scalars['String']>;
  brand: Brand;
  btw: Scalars['Float'];
  bundle_amount?: Maybe<Scalars['Int']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  discount_rules: Array<ProductDiscountLine>;
  discount_rules_count?: Maybe<Scalars['Int']>;
  downloads: Array<Scalars['PrivateFile']>;
  /** Geef de waarde aan van dit product in de giftshop. */
  entercoins?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  in_giftshop?: Maybe<Scalars['Boolean']>;
  in_webshop?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  minimum_purchase?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  /** Geef de waarde in punten aan als dit product binnen het marketingbudget wordt aangeschaft. */
  punten?: Maybe<Scalars['Int']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  slider_images: Array<Scalars['Image']>;
  slug: Scalars['String'];
  stock: Scalars['Int'];
  title: Scalars['String'];
  type: ProductTypeEnum;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type ProductActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type ProductImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type ProductSlider_ImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type ProductDiscountLine = {
  __typename?: 'ProductDiscountLine';
  amount?: Maybe<Scalars['Int']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ProductTypeEnum {
  Marketing = 'marketing',
  Product = 'product'
}

export type Query = {
  __typename?: 'Query';
  accountManagers: Array<User_7fc4a72701e8853451990b9e47f2f940>;
  brand: Brand;
  brands: Array<Brand>;
  cart: Cart;
  categories: Array<Category>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  downloadCategories: Array<Category>;
  downloads?: Maybe<DownloadPagination>;
  form?: Maybe<Form>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  menu?: Maybe<Menu>;
  menus?: Maybe<Array<Maybe<Menu>>>;
  myAccountManager?: Maybe<User_701f41990c9012abb9cd2cc0f94d7bec>;
  myOrder: Bestelling;
  myOrders?: Maybe<BestellingPagination>;
  myTrainings?: Maybe<AanmeldingPagination>;
  offers: Array<Offer>;
  page?: Maybe<Page>;
  product: Product;
  productCategories: Array<Category>;
  productCategory: Category;
  products: Array<Product>;
  settings: Array<Setting>;
  training: Training;
  trainingBrands: Array<Brand>;
  trainingUpcoming: Array<Trainingdate>;
  trainings: Array<Training>;
  videoCategories: Array<Category>;
  videos: Array<Video>;
  vragen: Array<Vraag>;
  webshopSettings: WebshopSettings;
};


export type QueryBrandArgs = {
  slug: Scalars['String'];
};


export type QueryCategoriesArgs = {
  hierarchy?: InputMaybe<Scalars['Boolean']>;
  resource_id?: InputMaybe<Scalars['ID']>;
};


export type QueryDownloadsArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFormArgs = {
  id: Scalars['ID'];
};


export type QueryMenuArgs = {
  name: Scalars['String'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMyOrderArgs = {
  uuid: Scalars['Uuid'];
};


export type QueryMyOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryMyTrainingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  slug: Scalars['String'];
};


export type QueryProductCategoriesArgs = {
  slug: Scalars['String'];
};


export type QueryProductCategoryArgs = {
  slug: Scalars['String'];
};


export type QueryProductsArgs = {
  brand?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['ID']>>;
  giftshop?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductTypeEnum>;
};


export type QueryTrainingArgs = {
  slug: Scalars['String'];
};


export type QueryTrainingUpcomingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingsArgs = {
  accreditation_points?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  training_levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVideosArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']>;
  meta_follow?: Maybe<Scalars['Boolean']>;
  meta_index?: Maybe<Scalars['Boolean']>;
  meta_title?: Maybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Mixed']>;
};

export type Template = TemplateStandaard;

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  tekst?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  locales: Array<Scalars['String']>;
  seo?: Maybe<Seo>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type TenantActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type TenantImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  seo?: Maybe<Seo>;
  title: Scalars['String'];
};


export type Tenant_3af8041b6556776eda1312e7c8acaed7Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Training = {
  __typename?: 'Training';
  accreditation_points?: Maybe<Scalars['Float']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  address?: Maybe<Address>;
  brand: Array<Brand>;
  brand_count?: Maybe<Scalars['Int']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  price?: Maybe<Scalars['Float']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  title: Scalars['String'];
  trainer?: Maybe<User_2e31fa5d9eb2dc327aa69afc747e71a0>;
  training_level?: Maybe<Scalars['String']>;
  trainingsdatums: Array<Trainingdate>;
  trainingsdatums_count?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};


export type TrainingActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type TrainingImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Trainingdate = {
  __typename?: 'Trainingdate';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  end_time?: Maybe<Scalars['String']>;
  has_lunch?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  start_time?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trainingen: Array<Training>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Transformatie = {
  __typename?: 'Transformatie';
  additional_products?: Maybe<Scalars['String']>;
  additional_treatments?: Maybe<Scalars['String']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  after: Array<Scalars['Image']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  before: Array<Scalars['Image']>;
  capabilities: Array<Capability>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  product?: Maybe<Product>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  time_transpired?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total_treatments?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type TransformatieAfterArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type TransformatieBeforeArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String'];
  email: Scalars['Email'];
  secret: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']>;
};

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  address?: Maybe<Address>;
  avatar: Scalars['String'];
  capabilities: Array<Capability>;
  company?: Maybe<Organisatie>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['Email'];
  entercoins: Scalars['Int'];
  first_name: Scalars['String'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  last_name: Scalars['String'];
  merken: Array<Brand>;
  merken_count?: Maybe<Scalars['Int']>;
  mobile_number?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  role?: Maybe<Role>;
  role_id: Scalars['ID'];
  short_description?: Maybe<Scalars['String']>;
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['Uuid'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4ImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type User_2e31fa5d9eb2dc327aa69afc747e71a0 = {
  __typename?: 'User_2e31fa5d9eb2dc327aa69afc747e71a0';
  avatar: Scalars['String'];
  name: Scalars['String'];
};


export type User_2e31fa5d9eb2dc327aa69afc747e71a0AvatarArgs = {
  w?: InputMaybe<Scalars['Int']>;
};

export type User_7fc4a72701e8853451990b9e47f2f940 = {
  __typename?: 'User_7fc4a72701e8853451990b9e47f2f940';
  email: Scalars['Email'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type User_701f41990c9012abb9cd2cc0f94d7bec = {
  __typename?: 'User_701f41990c9012abb9cd2cc0f94d7bec';
  email: Scalars['Email'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['PrivateFile']>;
  id: Scalars['Int'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']>;
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  vimeo_url?: Maybe<Scalars['String']>;
};


export type VideoActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};


export type VideoImagesArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  bri?: InputMaybe<Scalars['Int']>;
  dpr?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<Scalars['String']>;
  fm?: InputMaybe<Scalars['String']>;
  h?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
};

export type Vraag = {
  __typename?: 'Vraag';
  answer: Scalars['String'];
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  publish_at?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type WebshopSettings = {
  __typename?: 'WebshopSettings';
  delivery_fee: Scalars['Float'];
  for_every_x_amount: Scalars['Float'];
  free_delivery_from_amount: Scalars['Float'];
  get_x_entercoins: Scalars['Int'];
  minimum_order: Scalars['Float'];
  secured_shipment: Scalars['Float'];
};

export type BeforeAndAfterMutationVariables = Exact<{
  product: Scalars['String'];
  before: Scalars['Upload'];
  after: Scalars['Upload'];
  total_treatments?: InputMaybe<Scalars['String']>;
  additional_treatments?: InputMaybe<Scalars['String']>;
  additional_products?: InputMaybe<Scalars['String']>;
  time_transpired?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
}>;


export type BeforeAndAfterMutation = { __typename?: 'Mutation', beforeAndAfter?: null | null };

export type TrainingRegistrationMutationVariables = Exact<{
  training: Scalars['ID'];
  trainingDate: Scalars['ID'];
}>;


export type TrainingRegistrationMutation = { __typename?: 'Mutation', trainingRegistration?: Array<string | null> | null };

export type CloneOrderMutationVariables = Exact<{
  uuid: Scalars['Uuid'];
}>;


export type CloneOrderMutation = { __typename?: 'Mutation', cloneOrder?: null | null };

export type PlaceOrderMutationVariables = Exact<{
  address: Scalars['ID'];
  secured_shipment?: InputMaybe<Scalars['Boolean']>;
}>;


export type PlaceOrderMutation = { __typename?: 'Mutation', placeOrder?: null | null };

export type UpdateCartMutationVariables = Exact<{
  product: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  action: CartActionEnum;
  is_gift?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateCartMutation = { __typename?: 'Mutation', updateCart?: null | null };

export type MyTrainingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTrainingsQuery = { __typename?: 'Query', myTrainings?: { __typename?: 'AanmeldingPagination', current_page: number, has_more_pages: boolean, last_page: number, total: number, data: Array<{ __typename?: 'Aanmelding', date?: string | null, training?: { __typename?: 'Training', title: string, accreditation_points?: number | null, slug: string, price?: number | null, excerpt?: string | null } | null }> } | null };

export type TrainingQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type TrainingQuery = { __typename?: 'Query', training: { __typename?: 'Training', id: number, title: string, active_image?: Image | null, description?: string | null, accreditation_points?: number | null, training_level?: string | null, price?: number | null, trainer?: { __typename?: 'User_2e31fa5d9eb2dc327aa69afc747e71a0', name: string, avatar: string } | null, brand: Array<{ __typename?: 'Brand', title: string }>, address?: { __typename?: 'Address', title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null } | null, trainingsdatums: Array<{ __typename?: 'Trainingdate', id: number, date: string }> } };

export type TrainingBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingBrandsQuery = { __typename?: 'Query', trainingBrands: Array<{ __typename?: 'Brand', title: string, id: number }> };

export type TrainingUpcomingQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingUpcomingQuery = { __typename?: 'Query', trainingUpcoming: Array<{ __typename?: 'Trainingdate', id: number, title: string, date: string, start_time?: string | null, end_time?: string | null, has_lunch?: boolean | null, trainingen: Array<{ __typename?: 'Training', id: number, title: string, slug: string, excerpt?: string | null, price?: number | null, address?: { __typename?: 'Address', title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null } | null }> }> };

export type TrainingsQueryVariables = Exact<{
  brands?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  accreditation_points?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  training_levels?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type TrainingsQuery = { __typename?: 'Query', trainings: Array<{ __typename?: 'Training', id: number, title: string, slug: string, active_image?: Image | null, excerpt?: string | null, accreditation_points?: number | null, training_level?: string | null, price?: number | null, address?: { __typename?: 'Address', title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null } | null }> };

export type BrandQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type BrandQuery = { __typename?: 'Query', brand: { __typename?: 'Brand', slug: string, title: string, logo?: Image | null, background_image?: Image | null } };

export type BrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsQuery = { __typename?: 'Query', brands: Array<{ __typename?: 'Brand', id: number, slug: string, title: string, logo?: Image | null, background_image?: Image | null }> };

export type DownloadCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadCategoriesQuery = { __typename?: 'Query', downloadCategories: Array<{ __typename?: 'Category', id: number, name: string, slug: string }> };

export type DownloadsQueryVariables = Exact<{
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type DownloadsQuery = { __typename?: 'Query', downloads?: { __typename?: 'DownloadPagination', current_page: number, has_more_pages: boolean, per_page: number, last_page: number, total: number, data: Array<{ __typename?: 'Download', active_image?: Image | null, id: number, title: string, description?: string | null, downloads: Array<any> }> } | null };

export type VideoCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type VideoCategoriesQuery = { __typename?: 'Query', videoCategories: Array<{ __typename?: 'Category', name: string, slug: string, id: number }> };

export type VideosQueryVariables = Exact<{
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type VideosQuery = { __typename?: 'Query', videos: Array<{ __typename?: 'Video', id: number, title: string, description?: string | null, vimeo_url?: string | null, active_image?: Image | null, file: Array<any> }> };

export type VragenQueryVariables = Exact<{ [key: string]: never; }>;


export type VragenQuery = { __typename?: 'Query', vragen: Array<{ __typename?: 'Vraag', id: number, title: string, answer: string, categories: Array<{ __typename?: 'Category', id: number, name: string }> }> };

export type OffersQueryVariables = Exact<{ [key: string]: never; }>;


export type OffersQuery = { __typename?: 'Query', offers: Array<{ __typename?: 'Offer', id: number, title: string, active_image?: Image | null, product?: { __typename?: 'Product', id: number, title: string, slug: string, brand: { __typename?: 'Brand', slug: string } } | null }> };

export type PageQueryVariables = Exact<{
  segments: Scalars['String'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', url: string, title: string, slug: string, active_image?: Image | null, images: Array<Image>, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null } | null };

export type AccountManagersQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountManagersQuery = { __typename?: 'Query', accountManagers: Array<{ __typename?: 'User_7fc4a72701e8853451990b9e47f2f940', name: string, email: string, phone?: string | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4', uuid: string, first_name: string, name: string, email: string, phone?: string | null, mobile_number?: string | null, entercoins: number, company?: { __typename?: 'Organisatie', title: string, vat_number?: string | null, kvk_number?: string | null, email: string, invoice_email?: string | null, phone?: string | null, mobile_number?: string | null, addresses: Array<{ __typename?: 'Address', id: number, title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null }>, invoice_address?: { __typename?: 'Address', title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null } | null } | null } | null };

export type MyAccountManagerQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAccountManagerQuery = { __typename?: 'Query', myAccountManager?: { __typename?: 'User_701f41990c9012abb9cd2cc0f94d7bec', first_name: string, last_name: string, email: string, phone?: string | null, short_description?: string | null } | null };

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'Cart', count: number, product_sub_total: number, marketing_sub_total: number, sub_total: number, volume_discount: number, tax: number, delivery_cost: number, marketing_budget: number, entercoins_cost: number, entercoins_receive: number, minimum_order_amount: number, total: number, cart_items: Array<{ __typename?: 'CartItem', quantity: number, is_gift: boolean, product: { __typename?: 'Product', slug: string, title: string, price: number, type: ProductTypeEnum, stock: number, entercoins?: number | null, minimum_purchase?: number | null, active_image?: Image | null, bundle_amount?: number | null, active_category?: { __typename?: 'Category', name: string } | null } }> } };

export type MyOrderQueryVariables = Exact<{
  uuid: Scalars['Uuid'];
}>;


export type MyOrderQuery = { __typename?: 'Query', myOrder: { __typename?: 'Bestelling', id: number, uuid: string, created_at?: string | null, total?: number | null, tax?: number | null, entercoins?: number | null, total_products?: number | null, total_marketing_material?: number | null, order_lines: Array<{ __typename?: 'BestellingLijn', title: string, price?: number | null, quantity?: number | null, entercoins?: number | null, is_gift?: boolean | null, product?: { __typename?: 'Product', slug: string, title: string, active_image?: Image | null, type: ProductTypeEnum, language?: string | null, bundle_amount?: number | null } | null }> } };

export type MyOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrdersQuery = { __typename?: 'Query', myOrders?: { __typename?: 'BestellingPagination', current_page: number, has_more_pages: boolean, total: number, last_page: number, data: Array<{ __typename?: 'Bestelling', id: number, uuid: string, created_at?: string | null, updated_at?: string | null, total?: number | null, tax?: number | null, status: OrderStatusEnum, entercoins?: number | null, total_products?: number | null, total_marketing_material?: number | null, entercoins_change?: number | null }> } | null };

export type ProductQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', slug: string, title: string, price: number, entercoins?: number | null, active_image?: Image | null, stock: number, introduction?: string | null, instructions?: string | null, details?: string | null, downloads: Array<any>, minimum_purchase?: number | null, slider_images: Array<Image>, brand: { __typename?: 'Brand', slug: string, title: string }, discount_rules: Array<{ __typename?: 'ProductDiscountLine', id: number, amount?: number | null, discount?: number | null }> } };

export type ProductCategoriesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductCategoriesQuery = { __typename?: 'Query', productCategories: Array<{ __typename?: 'Category', slug: string, name: string, children: Array<{ __typename?: 'Category', slug: string, name: string }> }> };

export type ProductCategoryQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductCategoryQuery = { __typename?: 'Query', productCategory: { __typename?: 'Category', id: number, slug: string, text?: string | null, active_image?: Image | null } };

export type ProductsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductTypeEnum>;
  giftshop?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: number, slug: string, title: string, minimum_purchase?: number | null, price: number, entercoins?: number | null, stock: number, language?: string | null, bundle_amount?: number | null, active_image?: Image | null, brand: { __typename?: 'Brand', slug: string, title: string }, active_category?: { __typename?: 'Category', name: string } | null }> };

export type WebshopSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type WebshopSettingsQuery = { __typename?: 'Query', webshopSettings: { __typename?: 'WebshopSettings', minimum_order: number, delivery_fee: number, free_delivery_from_amount: number, for_every_x_amount: number, get_x_entercoins: number, secured_shipment: number } };


export const BeforeAndAfterDocument = gql`
    mutation beforeAndAfter($product: String!, $before: Upload!, $after: Upload!, $total_treatments: String, $additional_treatments: String, $additional_products: String, $time_transpired: String, $comments: String) {
  beforeAndAfter(
    product: $product
    before: $before
    after: $after
    total_treatments: $total_treatments
    additional_treatments: $additional_treatments
    additional_products: $additional_products
    time_transpired: $time_transpired
    comments: $comments
  )
}
    `;

/**
 * __useBeforeAndAfterMutation__
 *
 * To run a mutation, you first call `useBeforeAndAfterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useBeforeAndAfterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useBeforeAndAfterMutation({
 *   variables: {
 *     product: // value for 'product'
 *     before: // value for 'before'
 *     after: // value for 'after'
 *     total_treatments: // value for 'total_treatments'
 *     additional_treatments: // value for 'additional_treatments'
 *     additional_products: // value for 'additional_products'
 *     time_transpired: // value for 'time_transpired'
 *     comments: // value for 'comments'
 *   },
 * });
 */
export function useBeforeAndAfterMutation(options: VueApolloComposable.UseMutationOptions<BeforeAndAfterMutation, BeforeAndAfterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<BeforeAndAfterMutation, BeforeAndAfterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<BeforeAndAfterMutation, BeforeAndAfterMutationVariables>(BeforeAndAfterDocument, options);
}
export type BeforeAndAfterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<BeforeAndAfterMutation, BeforeAndAfterMutationVariables>;
export const TrainingRegistrationDocument = gql`
    mutation trainingRegistration($training: ID!, $trainingDate: ID!) {
  trainingRegistration(training: $training, trainingDate: $trainingDate)
}
    `;

/**
 * __useTrainingRegistrationMutation__
 *
 * To run a mutation, you first call `useTrainingRegistrationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useTrainingRegistrationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useTrainingRegistrationMutation({
 *   variables: {
 *     training: // value for 'training'
 *     trainingDate: // value for 'trainingDate'
 *   },
 * });
 */
export function useTrainingRegistrationMutation(options: VueApolloComposable.UseMutationOptions<TrainingRegistrationMutation, TrainingRegistrationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<TrainingRegistrationMutation, TrainingRegistrationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<TrainingRegistrationMutation, TrainingRegistrationMutationVariables>(TrainingRegistrationDocument, options);
}
export type TrainingRegistrationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<TrainingRegistrationMutation, TrainingRegistrationMutationVariables>;
export const CloneOrderDocument = gql`
    mutation cloneOrder($uuid: Uuid!) {
  cloneOrder(uuid: $uuid)
}
    `;

/**
 * __useCloneOrderMutation__
 *
 * To run a mutation, you first call `useCloneOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCloneOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCloneOrderMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCloneOrderMutation(options: VueApolloComposable.UseMutationOptions<CloneOrderMutation, CloneOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CloneOrderMutation, CloneOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CloneOrderMutation, CloneOrderMutationVariables>(CloneOrderDocument, options);
}
export type CloneOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CloneOrderMutation, CloneOrderMutationVariables>;
export const PlaceOrderDocument = gql`
    mutation placeOrder($address: ID!, $secured_shipment: Boolean) {
  placeOrder(address: $address, secured_shipment: $secured_shipment)
}
    `;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePlaceOrderMutation({
 *   variables: {
 *     address: // value for 'address'
 *     secured_shipment: // value for 'secured_shipment'
 *   },
 * });
 */
export function usePlaceOrderMutation(options: VueApolloComposable.UseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, options);
}
export type PlaceOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const UpdateCartDocument = gql`
    mutation updateCart($product: String!, $quantity: Int, $action: CartActionEnum!, $is_gift: Boolean) {
  updateCart(
    product: $product
    quantity: $quantity
    action: $action
    is_gift: $is_gift
  )
}
    `;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCartMutation({
 *   variables: {
 *     product: // value for 'product'
 *     quantity: // value for 'quantity'
 *     action: // value for 'action'
 *     is_gift: // value for 'is_gift'
 *   },
 * });
 */
export function useUpdateCartMutation(options: VueApolloComposable.UseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options);
}
export type UpdateCartMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCartMutation, UpdateCartMutationVariables>;
export const MyTrainingsDocument = gql`
    query myTrainings {
  myTrainings {
    data {
      training {
        title
        accreditation_points
        excerpt: description
        slug
        price
      }
      date
    }
    current_page
    has_more_pages
    last_page
    total
  }
}
    `;

/**
 * __useMyTrainingsQuery__
 *
 * To run a query within a Vue component, call `useMyTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTrainingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMyTrainingsQuery();
 */
export function useMyTrainingsQuery(options: VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MyTrainingsQuery, MyTrainingsQueryVariables>(MyTrainingsDocument, {}, options);
}
export function useMyTrainingsLazyQuery(options: VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyTrainingsQuery, MyTrainingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MyTrainingsQuery, MyTrainingsQueryVariables>(MyTrainingsDocument, {}, options);
}
export type MyTrainingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MyTrainingsQuery, MyTrainingsQueryVariables>;
export const TrainingDocument = gql`
    query training($slug: String!) {
  training(slug: $slug) {
    id
    title
    active_image(w: 440, h: 192, dpr: 2, fit: "crop")
    description
    accreditation_points
    training_level
    price
    trainer {
      name
      avatar
    }
    brand {
      title
    }
    address {
      title
      house_number
      house_number_suffix
      zipcode
      city
      country
    }
    trainingsdatums {
      id
      date
    }
  }
}
    `;

/**
 * __useTrainingQuery__
 *
 * To run a query within a Vue component, call `useTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrainingQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useTrainingQuery(variables: TrainingQueryVariables | VueCompositionApi.Ref<TrainingQueryVariables> | ReactiveFunction<TrainingQueryVariables>, options: VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, variables, options);
}
export function useTrainingLazyQuery(variables: TrainingQueryVariables | VueCompositionApi.Ref<TrainingQueryVariables> | ReactiveFunction<TrainingQueryVariables>, options: VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingQuery, TrainingQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrainingQuery, TrainingQueryVariables>(TrainingDocument, variables, options);
}
export type TrainingQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrainingQuery, TrainingQueryVariables>;
export const TrainingBrandsDocument = gql`
    query trainingBrands {
  trainingBrands {
    title
    id
  }
}
    `;

/**
 * __useTrainingBrandsQuery__
 *
 * To run a query within a Vue component, call `useTrainingBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingBrandsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrainingBrandsQuery();
 */
export function useTrainingBrandsQuery(options: VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrainingBrandsQuery, TrainingBrandsQueryVariables>(TrainingBrandsDocument, {}, options);
}
export function useTrainingBrandsLazyQuery(options: VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingBrandsQuery, TrainingBrandsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrainingBrandsQuery, TrainingBrandsQueryVariables>(TrainingBrandsDocument, {}, options);
}
export type TrainingBrandsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrainingBrandsQuery, TrainingBrandsQueryVariables>;
export const TrainingUpcomingDocument = gql`
    query trainingUpcoming {
  trainingUpcoming {
    id
    title
    date
    start_time
    end_time
    has_lunch
    trainingen {
      id
      title
      slug
      excerpt
      price
      address {
        title
        house_number
        house_number_suffix
        zipcode
        city
        country
      }
    }
  }
}
    `;

/**
 * __useTrainingUpcomingQuery__
 *
 * To run a query within a Vue component, call `useTrainingUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingUpcomingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrainingUpcomingQuery();
 */
export function useTrainingUpcomingQuery(options: VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>(TrainingUpcomingDocument, {}, options);
}
export function useTrainingUpcomingLazyQuery(options: VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>(TrainingUpcomingDocument, {}, options);
}
export type TrainingUpcomingQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrainingUpcomingQuery, TrainingUpcomingQueryVariables>;
export const TrainingsDocument = gql`
    query trainings($brands: [ID], $accreditation_points: [String], $training_levels: [String]) {
  trainings(
    brands: $brands
    accreditation_points: $accreditation_points
    training_levels: $training_levels
  ) {
    id
    title
    slug
    active_image(w: 440, h: 192, dpr: 2, fit: "crop")
    excerpt
    accreditation_points
    training_level
    price
    address {
      title
      house_number
      house_number_suffix
      zipcode
      city
      country
    }
  }
}
    `;

/**
 * __useTrainingsQuery__
 *
 * To run a query within a Vue component, call `useTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrainingsQuery({
 *   brands: // value for 'brands'
 *   accreditation_points: // value for 'accreditation_points'
 *   training_levels: // value for 'training_levels'
 * });
 */
export function useTrainingsQuery(variables: TrainingsQueryVariables | VueCompositionApi.Ref<TrainingsQueryVariables> | ReactiveFunction<TrainingsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, variables, options);
}
export function useTrainingsLazyQuery(variables: TrainingsQueryVariables | VueCompositionApi.Ref<TrainingsQueryVariables> | ReactiveFunction<TrainingsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrainingsQuery, TrainingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, variables, options);
}
export type TrainingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrainingsQuery, TrainingsQueryVariables>;
export const BrandDocument = gql`
    query brand($slug: String!) {
  brand(slug: $slug) {
    slug
    title
    logo
    background_image(dpr: 2, w: 400)
  }
}
    `;

/**
 * __useBrandQuery__
 *
 * To run a query within a Vue component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBrandQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useBrandQuery(variables: BrandQueryVariables | VueCompositionApi.Ref<BrandQueryVariables> | ReactiveFunction<BrandQueryVariables>, options: VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BrandQuery, BrandQueryVariables>(BrandDocument, variables, options);
}
export function useBrandLazyQuery(variables: BrandQueryVariables | VueCompositionApi.Ref<BrandQueryVariables> | ReactiveFunction<BrandQueryVariables>, options: VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BrandQuery, BrandQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BrandQuery, BrandQueryVariables>(BrandDocument, variables, options);
}
export type BrandQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BrandQuery, BrandQueryVariables>;
export const BrandsDocument = gql`
    query brands @cache(ttl: 15) {
  brands {
    id
    slug
    title
    logo
    background_image(dpr: 2, w: 400)
  }
}
    `;

/**
 * __useBrandsQuery__
 *
 * To run a query within a Vue component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBrandsQuery();
 */
export function useBrandsQuery(options: VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, {}, options);
}
export function useBrandsLazyQuery(options: VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BrandsQuery, BrandsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, {}, options);
}
export type BrandsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BrandsQuery, BrandsQueryVariables>;
export const DownloadCategoriesDocument = gql`
    query downloadCategories {
  downloadCategories {
    id
    name
    slug
  }
}
    `;

/**
 * __useDownloadCategoriesQuery__
 *
 * To run a query within a Vue component, call `useDownloadCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDownloadCategoriesQuery();
 */
export function useDownloadCategoriesQuery(options: VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>(DownloadCategoriesDocument, {}, options);
}
export function useDownloadCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>(DownloadCategoriesDocument, {}, options);
}
export type DownloadCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DownloadCategoriesQuery, DownloadCategoriesQueryVariables>;
export const DownloadsDocument = gql`
    query downloads($categories: [ID], $page: Int, $limit: Int) {
  downloads(categories: $categories, page: $page, limit: $limit) {
    data {
      active_image(w: 135, h: 100, dpr: 2, fit: "crop")
      id
      title
      description
      downloads
    }
    current_page
    has_more_pages
    per_page
    last_page
    total
  }
}
    `;

/**
 * __useDownloadsQuery__
 *
 * To run a query within a Vue component, call `useDownloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDownloadsQuery({
 *   categories: // value for 'categories'
 *   page: // value for 'page'
 *   limit: // value for 'limit'
 * });
 */
export function useDownloadsQuery(variables: DownloadsQueryVariables | VueCompositionApi.Ref<DownloadsQueryVariables> | ReactiveFunction<DownloadsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DownloadsQuery, DownloadsQueryVariables>(DownloadsDocument, variables, options);
}
export function useDownloadsLazyQuery(variables: DownloadsQueryVariables | VueCompositionApi.Ref<DownloadsQueryVariables> | ReactiveFunction<DownloadsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DownloadsQuery, DownloadsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DownloadsQuery, DownloadsQueryVariables>(DownloadsDocument, variables, options);
}
export type DownloadsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DownloadsQuery, DownloadsQueryVariables>;
export const VideoCategoriesDocument = gql`
    query videoCategories {
  videoCategories {
    name
    slug
    id
  }
}
    `;

/**
 * __useVideoCategoriesQuery__
 *
 * To run a query within a Vue component, call `useVideoCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVideoCategoriesQuery();
 */
export function useVideoCategoriesQuery(options: VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VideoCategoriesQuery, VideoCategoriesQueryVariables>(VideoCategoriesDocument, {}, options);
}
export function useVideoCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VideoCategoriesQuery, VideoCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VideoCategoriesQuery, VideoCategoriesQueryVariables>(VideoCategoriesDocument, {}, options);
}
export type VideoCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VideoCategoriesQuery, VideoCategoriesQueryVariables>;
export const VideosDocument = gql`
    query videos($categories: [ID]) {
  videos(categories: $categories) {
    id
    title
    description
    vimeo_url
    active_image
    file
  }
}
    `;

/**
 * __useVideosQuery__
 *
 * To run a query within a Vue component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVideosQuery({
 *   categories: // value for 'categories'
 * });
 */
export function useVideosQuery(variables: VideosQueryVariables | VueCompositionApi.Ref<VideosQueryVariables> | ReactiveFunction<VideosQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VideosQuery, VideosQueryVariables>(VideosDocument, variables, options);
}
export function useVideosLazyQuery(variables: VideosQueryVariables | VueCompositionApi.Ref<VideosQueryVariables> | ReactiveFunction<VideosQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VideosQuery, VideosQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VideosQuery, VideosQueryVariables>(VideosDocument, variables, options);
}
export type VideosQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VideosQuery, VideosQueryVariables>;
export const VragenDocument = gql`
    query vragen {
  vragen {
    id
    title
    answer
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useVragenQuery__
 *
 * To run a query within a Vue component, call `useVragenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVragenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVragenQuery();
 */
export function useVragenQuery(options: VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VragenQuery, VragenQueryVariables>(VragenDocument, {}, options);
}
export function useVragenLazyQuery(options: VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VragenQuery, VragenQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VragenQuery, VragenQueryVariables>(VragenDocument, {}, options);
}
export type VragenQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VragenQuery, VragenQueryVariables>;
export const OffersDocument = gql`
    query offers {
  offers {
    id
    title
    product {
      id
      title
      slug
      brand {
        slug
      }
    }
    active_image
  }
}
    `;

/**
 * __useOffersQuery__
 *
 * To run a query within a Vue component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOffersQuery();
 */
export function useOffersQuery(options: VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OffersQuery, OffersQueryVariables>(OffersDocument, {}, options);
}
export function useOffersLazyQuery(options: VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OffersQuery, OffersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OffersQuery, OffersQueryVariables>(OffersDocument, {}, options);
}
export type OffersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OffersQuery, OffersQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) {
  page(segments: $segments) {
    url
    title
    slug
    active_image
    images
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const AccountManagersDocument = gql`
    query accountManagers {
  accountManagers {
    name
    email
    phone
  }
}
    `;

/**
 * __useAccountManagersQuery__
 *
 * To run a query within a Vue component, call `useAccountManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountManagersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAccountManagersQuery();
 */
export function useAccountManagersQuery(options: VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AccountManagersQuery, AccountManagersQueryVariables>(AccountManagersDocument, {}, options);
}
export function useAccountManagersLazyQuery(options: VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AccountManagersQuery, AccountManagersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AccountManagersQuery, AccountManagersQueryVariables>(AccountManagersDocument, {}, options);
}
export type AccountManagersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AccountManagersQuery, AccountManagersQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    uuid
    first_name
    name
    email
    phone
    mobile_number
    entercoins
    company {
      title
      vat_number
      kvk_number
      email
      invoice_email
      phone
      mobile_number
      addresses {
        id
        title
        house_number
        house_number_suffix
        zipcode
        city
        country
      }
      invoice_address {
        title
        house_number
        house_number_suffix
        zipcode
        city
        country
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const MyAccountManagerDocument = gql`
    query myAccountManager {
  myAccountManager {
    first_name
    last_name
    email
    phone
    short_description
  }
}
    `;

/**
 * __useMyAccountManagerQuery__
 *
 * To run a query within a Vue component, call `useMyAccountManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountManagerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMyAccountManagerQuery();
 */
export function useMyAccountManagerQuery(options: VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MyAccountManagerQuery, MyAccountManagerQueryVariables>(MyAccountManagerDocument, {}, options);
}
export function useMyAccountManagerLazyQuery(options: VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyAccountManagerQuery, MyAccountManagerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MyAccountManagerQuery, MyAccountManagerQueryVariables>(MyAccountManagerDocument, {}, options);
}
export type MyAccountManagerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MyAccountManagerQuery, MyAccountManagerQueryVariables>;
export const CartDocument = gql`
    query cart {
  cart {
    cart_items {
      quantity
      is_gift
      product {
        slug
        title
        price
        type
        stock
        entercoins
        minimum_purchase
        active_image
        active_category {
          name
        }
        bundle_amount
      }
    }
    count
    product_sub_total
    marketing_sub_total
    sub_total
    volume_discount
    tax
    delivery_cost
    marketing_budget
    entercoins_cost
    entercoins_receive
    minimum_order_amount
    total
  }
}
    `;

/**
 * __useCartQuery__
 *
 * To run a query within a Vue component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCartQuery();
 */
export function useCartQuery(options: VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CartQuery, CartQueryVariables>(CartDocument, {}, options);
}
export function useCartLazyQuery(options: VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CartQuery, CartQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, {}, options);
}
export type CartQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CartQuery, CartQueryVariables>;
export const MyOrderDocument = gql`
    query myOrder($uuid: Uuid!) {
  myOrder(uuid: $uuid) {
    id
    uuid
    created_at
    total
    tax
    entercoins
    total_products
    total_marketing_material
    order_lines {
      title
      price
      quantity
      entercoins
      is_gift
      product {
        slug
        title
        active_image
        type
        language
        bundle_amount
      }
    }
  }
}
    `;

/**
 * __useMyOrderQuery__
 *
 * To run a query within a Vue component, call `useMyOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMyOrderQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useMyOrderQuery(variables: MyOrderQueryVariables | VueCompositionApi.Ref<MyOrderQueryVariables> | ReactiveFunction<MyOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MyOrderQuery, MyOrderQueryVariables>(MyOrderDocument, variables, options);
}
export function useMyOrderLazyQuery(variables: MyOrderQueryVariables | VueCompositionApi.Ref<MyOrderQueryVariables> | ReactiveFunction<MyOrderQueryVariables>, options: VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyOrderQuery, MyOrderQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MyOrderQuery, MyOrderQueryVariables>(MyOrderDocument, variables, options);
}
export type MyOrderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MyOrderQuery, MyOrderQueryVariables>;
export const MyOrdersDocument = gql`
    query myOrders {
  myOrders {
    data {
      id
      uuid
      created_at
      updated_at
      total
      tax
      status
      entercoins
      total_products
      total_marketing_material
      entercoins_change
    }
    current_page
    has_more_pages
    total
    last_page
  }
}
    `;

/**
 * __useMyOrdersQuery__
 *
 * To run a query within a Vue component, call `useMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMyOrdersQuery();
 */
export function useMyOrdersQuery(options: VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, {}, options);
}
export function useMyOrdersLazyQuery(options: VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MyOrdersQuery, MyOrdersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, {}, options);
}
export type MyOrdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MyOrdersQuery, MyOrdersQueryVariables>;
export const ProductDocument = gql`
    query product($slug: String!) {
  product(slug: $slug) {
    slug
    title
    price
    entercoins
    brand {
      slug
      title
    }
    discount_rules {
      id
      amount
      discount
    }
    active_image
    stock
    introduction
    instructions
    details
    downloads
    minimum_purchase
    slider_images(dpr: 2, w: 800)
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a Vue component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useProductQuery(variables: ProductQueryVariables | VueCompositionApi.Ref<ProductQueryVariables> | ReactiveFunction<ProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, variables, options);
}
export function useProductLazyQuery(variables: ProductQueryVariables | VueCompositionApi.Ref<ProductQueryVariables> | ReactiveFunction<ProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, variables, options);
}
export type ProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductQuery, ProductQueryVariables>;
export const ProductCategoriesDocument = gql`
    query productCategories($slug: String!) @cache(ttl: 15) {
  productCategories(slug: $slug) {
    slug
    name
    children {
      slug
      name
    }
  }
}
    `;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a Vue component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductCategoriesQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useProductCategoriesQuery(variables: ProductCategoriesQueryVariables | VueCompositionApi.Ref<ProductCategoriesQueryVariables> | ReactiveFunction<ProductCategoriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, variables, options);
}
export function useProductCategoriesLazyQuery(variables: ProductCategoriesQueryVariables | VueCompositionApi.Ref<ProductCategoriesQueryVariables> | ReactiveFunction<ProductCategoriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, variables, options);
}
export type ProductCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductCategoryDocument = gql`
    query productCategory($slug: String!) {
  productCategory(slug: $slug) {
    id
    slug
    text
    active_image(dpr: 2, w: 400)
  }
}
    `;

/**
 * __useProductCategoryQuery__
 *
 * To run a query within a Vue component, call `useProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductCategoryQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useProductCategoryQuery(variables: ProductCategoryQueryVariables | VueCompositionApi.Ref<ProductCategoryQueryVariables> | ReactiveFunction<ProductCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, variables, options);
}
export function useProductCategoryLazyQuery(variables: ProductCategoryQueryVariables | VueCompositionApi.Ref<ProductCategoryQueryVariables> | ReactiveFunction<ProductCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductCategoryQuery, ProductCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductCategoryQuery, ProductCategoryQueryVariables>(ProductCategoryDocument, variables, options);
}
export type ProductCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductCategoryQuery, ProductCategoryQueryVariables>;
export const ProductsDocument = gql`
    query products($query: String, $sort: String, $brand: String, $type: ProductTypeEnum, $giftshop: Boolean, $categories: [ID!]) {
  products(
    query: $query
    sort: $sort
    brand: $brand
    type: $type
    giftshop: $giftshop
    categories: $categories
  ) {
    id
    slug
    title
    minimum_purchase
    price
    entercoins
    brand {
      slug
      title
    }
    stock
    language
    bundle_amount
    active_image(dpr: 2, w: 400)
    active_category {
      name
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a Vue component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductsQuery({
 *   query: // value for 'query'
 *   sort: // value for 'sort'
 *   brand: // value for 'brand'
 *   type: // value for 'type'
 *   giftshop: // value for 'giftshop'
 *   categories: // value for 'categories'
 * });
 */
export function useProductsQuery(variables: ProductsQueryVariables | VueCompositionApi.Ref<ProductsQueryVariables> | ReactiveFunction<ProductsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);
}
export function useProductsLazyQuery(variables: ProductsQueryVariables | VueCompositionApi.Ref<ProductsQueryVariables> | ReactiveFunction<ProductsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);
}
export type ProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductsQuery, ProductsQueryVariables>;
export const WebshopSettingsDocument = gql`
    query webshopSettings {
  webshopSettings {
    minimum_order
    delivery_fee
    free_delivery_from_amount
    for_every_x_amount
    get_x_entercoins
    secured_shipment
  }
}
    `;

/**
 * __useWebshopSettingsQuery__
 *
 * To run a query within a Vue component, call `useWebshopSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebshopSettingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWebshopSettingsQuery();
 */
export function useWebshopSettingsQuery(options: VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WebshopSettingsQuery, WebshopSettingsQueryVariables>(WebshopSettingsDocument, {}, options);
}
export function useWebshopSettingsLazyQuery(options: VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WebshopSettingsQuery, WebshopSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WebshopSettingsQuery, WebshopSettingsQueryVariables>(WebshopSettingsDocument, {}, options);
}
export type WebshopSettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WebshopSettingsQuery, WebshopSettingsQueryVariables>;